import { Box } from '@chakra-ui/react';
import Hero from './home/Hero';
import Header from './home/Header';
import Footer from './home/Footer';
import Trusted from './home/Trusted';

function Home() {
  return (
    <Box>
      <Header />
      <Hero />
      <Trusted />
      <Footer />
    </Box>
  );
}

export default Home;
