import React from 'react';
import { Box, Text, Flex, Image, Link, Icon, Stack } from '@chakra-ui/react';
import { FaYoutube, FaInstagram, FaTiktok, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <Box py={10}>
      <Flex
        maxW="1200px"
        mx="auto"
        px={4}
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        justifyContent="space-between"
      >
        <Flex 
          alignItems="center" 
          width={{ base: '100%', md: '33%' }}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          mb={{ base: 6, md: 0 }}
        >
          <Image src={logo} alt="Logo" boxSize="40px" mr={4} />
          <Text fontSize="2xl" fontWeight="600">
            hoo.be
          </Text>
        </Flex>
        
        <Flex
          alignItems="center"
          justifyContent="center"
          width={{ base: '100%', md: '33%' }}
          mb={{ base: 6, md: 0 }}
        >
          <Link href="https://www.youtube.com/" isExternal mx={2} mt={2}>
            <Icon as={FaYoutube} boxSize={6} color="gray.700" />
          </Link>
          <Link href="https://www.instagram.com/" isExternal mx={2} mt={2}>
            <Icon as={FaInstagram} boxSize={6} color="gray.700" />
          </Link>
          <Link href="https://www.tiktok.com/" isExternal mx={2} mt={2}>
            <Icon as={FaTiktok} boxSize={6} color="gray.700" />
          </Link>
          <Link href="https://www.twitter.com/" isExternal mx={2} mt={2}>
            <Icon as={FaXTwitter} boxSize={6} color="gray.700" />
          </Link>
          <Link href="mailto:info@yourapp.com" isExternal mx={2} mt={2}>
            <Icon as={FaEnvelope} boxSize={6} color="gray.700" />
          </Link>
        </Flex>
        
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'center', md: 'flex-end' }}
          justifyContent={{ base: 'center', md: 'flex-end' }}
          width={{ base: '100%', md: '33%' }}
          spacing={{ base: 2, sm: 0 }}
          mt={3}
        >
          <Link 
            href="/terms-of-service" 
            fontSize="14px" 
            mx={2}
            textDecoration="none" 
            _hover={{ textDecoration: 'none' }} 
            color="gray.500"
          >
            terms of service
          </Link>
          <Text fontSize="14px" mx={2} color="gray.500" display={{ base: 'none', sm: 'inline' }}>•</Text>
          <Text fontSize="14px" color="gray.500">
            © {new Date().getFullYear()}, All Rights Reserved
          </Text>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Footer;