import { Box, Flex, Text, Button, Image, IconButton, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from '../../assets/logo.png';
import { useState, useEffect } from 'react';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      color="black"
      px={8}
      position="sticky"
      top="0"
      zIndex="1000"
      transition="transform 0.3s ease"
      transform={scrolled ? 'translateY(20px)' : 'translateY(0)'}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        maxW="1200px"
        w="100%"
        mx="auto"
        bg={scrolled ? 'white' : 'transparent'}
        px={4}
        py={4}
        borderRadius="full"
        borderWidth="1px"
        borderColor={scrolled ? 'gray.200' : 'transparent'}
        transition="background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease"
      >
        {/* Logo and hoo.be text on the left */}
        <Flex alignItems="center">
          <Image src={logo} alt="Logo" boxSize="40px" mr={4} />
          <Text fontSize="3xl" fontWeight="600">
            hoo.be
          </Text>
        </Flex>

        {/* Hamburger menu for mobile, buttons for desktop */}
        <Flex alignItems="center">
          {/* Desktop view */}
          <Flex display={{ base: 'none', md: 'flex' }} alignItems="center">
            <Link to="/login">
              <Button borderRadius="full" width="150px" borderWidth="1px" bg="white" mr={4}>
                login
              </Button>
            </Link>
            <Link to="/signup">
              <Button borderRadius="full" width="150px" color="white" bg="black" _hover={{ bg: "#222222" }}>
                sign up
              </Button>
            </Link>
          </Flex>

          {/* Mobile view */}
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            icon={<HamburgerIcon />}
            variant="outline"
            borderRadius="full"
            onClick={onOpen}
            aria-label="Open Menu"
          />
        </Flex>
      </Flex>

      {/* Drawer for mobile, slides from the right */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Flex flexDirection="column" alignItems="left">
              <Link to="/login">
                <Button
                  onClick={onClose}
                  borderRadius="full"
                  width="150px"
                  borderWidth="1px"
                  bg="white"
                  mb={4}
                >
                  login
                </Button>
              </Link>
              <Link to="/signup">
                <Button
                  onClick={onClose}
                  borderRadius="full"
                  width="150px"
                  color="white"
                  bg="black"
                  _hover={{ bg: "#222222" }}
                >
                  sign up
                </Button>
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default Header;
