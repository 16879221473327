import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Heading, Image, useColorModeValue, Center, HStack, Link } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { FaInstagram } from 'react-icons/fa';

// Import the creator images
import charlyImage from '../../assets/creators/charly.webp';
import dudesignImage from '../../assets/creators/dude-sign.webp';
import harryImage from '../../assets/creators/harry.webp';
import michaelImage from '../../assets/creators/michael.webp';
import pitbullImage from '../../assets/creators/pitbull.webp';
import sommerImage from '../../assets/creators/sommer.webp';
import tombradyImage from '../../assets/creators/tom-brady.webp';
import tooturntImage from '../../assets/creators/tooturnt.webp';
import martinImage from '../../assets/creators/martin-garrix.jpg';
import davidImage from '../../assets/creators/david.jpg';
import jeffImage from '../../assets/creators/jeff.jpg';

const MotionBox = motion(Box);

const creators = [
    { name: 'Martin Garrix', role: 'artist', followers: '15M', image: martinImage, link: 'https://hoo.be/martingarrix' },
    { name: 'David Dobrik', role: 'blogger', followers: '9.9M', image: davidImage, link: 'https://hoo.be/daviddobrik' },
    { name: 'Charly Jordan', role: 'dj', followers: '4.4M', image: charlyImage, link: 'https://hoo.be/charlyjordan' },
    { name: 'T O O T U R N T', role: 'artist', followers: '4.8M', image: tooturntImage, link: 'https://hoo.be/tooturnt' },
    { name: 'dude with sign', role: 'blogger', followers: '7.9M', image: dudesignImage, link: 'https://hoo.be/dudewithsign' },
    { name: 'Harry Jowsey', role: 'gamer', followers: '4.2M', image: harryImage, link: 'https://hoo.be/harryjowsey' },
    { name: 'Michael Evans Behling', role: 'artist', followers: '1.6M', image: michaelImage, link: 'https://hoo.be/michaelb05' },
    { name: 'Pitbull', role: 'artist', followers: '10.8M', image: pitbullImage, link: 'https://hoo.be/pitbull' },
    { name: 'Sommer Ray', role: 'model', followers: '24.9M', image: sommerImage, link: 'https://hoo.be/sommer' },
    { name: 'Tom Brady', role: 'athelte', followers: '15M', image: tombradyImage, link: 'https://hoo.be/tombrady' },
    { name: 'Jeff Bezos', role: 'entrepeneur', followers: '4.4M', image: jeffImage, link: 'https://hoo.be/jeffbezos' },
];

const CreatorCard = ({ creator }) => {
    const bgColor = useColorModeValue('white', 'gray.800');

    return (
        <Link href={creator.link} isExternal _hover={{ textDecoration: 'none' }}>
            <VStack
                w="180px"
                bg={bgColor}
                borderRadius="xl"
                overflow="hidden"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-15px)' }}
            >
                <Image
                    src={creator.image}
                    alt={creator.name}
                    h="100px"
                    objectFit="cover"
                    mx="auto"
                    borderRadius="xl"
                />
                <VStack p={4} align="center" spacing={1} w="100%">
                    <Center>
                        <Heading
                            size="sm"
                            zIndex={1000}
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                        >
                            {creator.name}
                        </Heading>
                    </Center>
                    <Center>
                        <Text fontSize="sm" color="gray.500">{creator.role}</Text>
                    </Center>
                    <Center>
                        <HStack spacing={2}>
                            <Box as={FaInstagram} color="gray.500" size="20px" />
                            <Text fontSize="sm" color='gray.500'>{creator.followers}</Text>
                        </HStack>
                    </Center>
                </VStack>
            </VStack>
        </Link>
    );
};

const Trusted = () => {
    const controls = useAnimation();
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const calculateWidth = () => {
            const cardWidth = 180;
            const margin = 16;
            return creators.length * (cardWidth + margin);
        };

        setWidth(calculateWidth());
    }, []);

    useEffect(() => {
        if (width > 0) {
            controls.start({
                x: [-width, 0],
                transition: {
                    x: {
                        repeat: Infinity,
                        repeatType: 'loop',
                        duration: 45,
                        ease: 'linear',
                    },
                },
            });
        }
    }, [controls, width]);

    return (
        <Box mt={150} mb={150} overflow="hidden">
            <Heading textAlign="center" mb={8} fontSize="2xl">trusted by top creators</Heading>
            <MotionBox
                display="flex"
                animate={controls}
                style={{ width: `${width * 2}px` }}
            >
                {[...creators, ...creators].map((creator, index) => (
                    <Box key={`${creator.name}-${index}`} mr={4}>
                        <CreatorCard creator={creator} />
                    </Box>
                ))}
            </MotionBox>
        </Box>
    );
};

export default Trusted;