import React from 'react';
import { Box, Flex, Heading, Text, Button, Input, InputGroup, InputRightElement, InputLeftElement, keyframes, useMediaQuery } from '@chakra-ui/react';
import { FiLink } from 'react-icons/fi';
import heroImage from '../../assets/hero.jpg';

const slideInFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideUpFadeFromOutside = keyframes`
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Hero = () => {

    const [isMobile] = useMediaQuery("(max-width: 48em)");

  return (
    <Flex
      height={{ base: "auto", md: "90vh" }}
      justifyContent="center"
      alignItems="center"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        right={{
          md: "-60%",
          lg: "-45%",
          xl: "-30%"
        }}
        top="0"
        width="75%"
        height="100%"
        backgroundImage={`url(${heroImage})`}
        backgroundPosition="left center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        display={{ base: "none", md: "block" }}
        animation={`${slideInFade} 1.5s ease-out`}
      />
      <Box
        width={{ base: "100%", md: "1200px" }}
        p={10}
        align="left"
        position="relative"
        zIndex="1"
        animation={`${slideUpFadeFromOutside} 1.5s ease-out`}
      >
        <Heading 
          as="h1" 
          fontSize={{ base: "40px", md: "64px" }} 
          fontWeight="bold" 
          mb={4}
        >
          more than just <br /> a link, it's your <br /> home on the web
        </Heading>
        <Text 
          fontSize="lg" 
          mb={6}
        >
          hoo.be empowers creators to turn every link into an opportunity
        </Text>
        <InputGroup 
          size="lg" 
          mb={6} 
          maxWidth="600px"
        >
          <InputLeftElement
            pointerEvents="none"
            children={<FiLink color="gray.300" />}
            fontSize="24px"
            height="auto"
            h="75px"
            ml="8px"
          />
          <Input borderRadius="xl" h="75px" placeholder="hoo.be/handle" pl="48px" />
          <InputRightElement height="auto" width="auto">
            <Button m={4} bg="black" color="white" _hover={{ bg: "gray.800" }}>
            {isMobile ? "create" : "create your hoo.be"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
    </Flex>
  );
};

export default Hero;